<template>
	<v-card v-if="items" flat color="transparent">
		<v-row class="ma-0 pa-0">
			<v-col
				v-for="(item, index) in items"
				:key="index" dark
				cols="12" class="pa-1"
				xl="3" lg="3"
				md="3" sm="6"
			>
				<v-card
					min-height="135" dark
					:color="item.color"
					class="px-6 py-2 site-summary-item"
				>
					<div class="item-title">
						<div class="item-count">{{ item.count }}</div>
						<div class="item-title">{{item.title}}</div>
					</div>
					<v-icon class="item-icon" size="50">{{ item.icon }}</v-icon>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
</template>
<script>
export default {
	name: "SiteSummary",
	data: () => ({
		items: null,
	}),
	created() {
		this.$axios.get(this.$urls.summary)
		.then(res => {
			this.items = [
				{
					title: "Today's Attendance",
					count: res["present_today"],
					icon: "mdi-fingerprint",
					color: "primary"
				},
				{
					title: "No. of Staffs",
					count: res["no_of_staffs"],
					icon: "mdi-account-group",
					color: "teal"
				},
				{
					title: "Staffs in leave today",
					count: res["absent_today"],
					icon: "mdi-account-outline",
					color: "orange lighten-1"
				},
				{
					title: "Pending leave requests",
					count: res["pending_leaves"],
					icon: "mdi-account-clock",
					color: "red lighten-1"
				}
			]
		})
		.catch(e => {
			console.log(e)
		})
	}
};
</script>
<style lang="scss" scoped>
@import "src/styles/variables.scss";
.site-summary-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	.item-title {
		font-size: .9rem;
		font-weight: 500;
	}
	.item-count {
		transition: text-shadow .3s ease;
		font-size: 2.8rem;
	}
}
//.site-summary-item:nth-child(1) {
//	.item-count {
//		color: lighten($primary, 4);
//	}
//	.item-title, .item-icon {
//		color: #a7c4ee;
//	}
//}
//.site-summary-item:nth-child(2) {
//	.item-count {
//		color: #16a416;
//	}
//	.item-title, .item-icon {
//		color: #7cc97c;
//	}
//}
//.site-summary-item:nth-child(3) {
//	.item-count {
//		color: #9f29f6;
//	}
//	.item-title, .item-icon {
//		color: #d1a2f6;
//	}
//}
//.site-summary-item:nth-child(4) {
//	.item-count {
//		color: #f84545;
//	}
//	.item-title, .item-icon {
//		color: #f89e9e;
//	}
//}
//.site-summary-item:hover {
//	.item-count {
//		color: whitesmoke;
//	}
//	.item-title {
//		color: whitesmoke;
//	}
//	.item-icon {
//		color: whitesmoke !important;
//	}
//}
//.site-summary-item:nth-child(1):hover {
//	background-color: lighten($primary, 4);
//}
//.site-summary-item:nth-child(2):hover {
//	background-color: #16a416;
//}
//.site-summary-item:nth-child(3):hover {
//	background-color: #9f29f6;
//}
//.site-summary-item:nth-child(4):hover {
//	background-color: #f84545;
//}
</style>
